import {
  Component, Inject, OnInit, inject,
} from '@angular/core';
import {
  animate, state, style, transition, trigger,
} from '@angular/animations';
import { RouterOutlet } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { LoadingIndicatorComponent, BrowserDetectionService, APPLICATION_NAME } from './libs/core';
import { FetchLookupData } from './actions';

@Component({
  selector: 'soq',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),
      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
    trigger('contentFadeAnimation', [
      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    LoadingIndicatorComponent,
    AsyncPipe,
    AppHeaderComponent,
    AppFooterComponent,
  ],
})
export class AppComponent implements OnInit {
  public static readonly version: string = APP_VERSION;
  public static readonly buildNumber: string = BUILD_NUMBER;

  public isLoading: boolean = false;

  private readonly browserDetectionService = inject(BrowserDetectionService);
  private readonly store = inject(Store);

  constructor(
    @Inject(APPLICATION_NAME) private readonly applicationName: string,
  ) {
    // eslint-disable-next-line rxjs/no-ignored-observable
    this.store.dispatch(new FetchLookupData());
  }

  public ngOnInit(): void {
    this.showBrowserInfo();
  }

  private showBrowserInfo(): void {
    if (!this.browserDetectionService.isPlatformBrowser()) {
      return;
    }

    const versionAndBuild = `Version ${AppComponent.version} | Build ${AppComponent.buildNumber}`;

    if (!this.browserDetectionService.isIEOrEdge()) {
      const args = [
        `\n%c${this.applicationName}\n\n%c${versionAndBuild}\n\n`,
        'color: #ff000f; font-weight: 700; font-size: 2em; text-shadow: 1px 1px 0px white, 1px -1px 0px white, -1px 1px 0px white, -1px -1px 0px white;',
        'color: #262626; background: #fff',
        'color: #ff2424; background: #fff',
        'color: #262626; background: #fff',
      ];

      window.console.log(...args);
    } else {
      window.console.log(`\n${this.applicationName}\n${versionAndBuild}\n`);
    }
  }
}
