/* eslint-disable no-console */

import {
  DestroyRef,
  Injectable,
  Injector,
  Optional,
} from '@angular/core';
import {
  NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,
} from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as Common from '@microsoft/applicationinsights-common';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ICustomProperties, ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { filter } from 'rxjs/operators';
import { AngularApplicationInsightsConfig } from '../config';
import { AppInsightsPublicInterface } from '../types/application-insights';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService implements Omit<AppInsightsPublicInterface, 'getCookieMgr'> {
  public appInsights?: ApplicationInsights;

  public get context(): ApplicationInsights['context'] | undefined {
    return this.appInsights?.context;
  }

  constructor(
    private readonly _injector: Injector,
    private readonly componentRef: DestroyRef,
    @Optional() private readonly config?: AngularApplicationInsightsConfig,
  ) {}

  /**
   * Log a user action or other occurrence.
   */
  public trackEvent(event: Common.IEventTelemetry, customProperties?: ICustomProperties): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackEvent(event, customProperties);
      } catch (ex) {
        console.warn('Angular application insights Error [trackEvent]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  public trackPageView(pageView?: Common.IPageViewTelemetry): void;

  /** @deprecated */
  public trackPageView(name?: Common.IPageViewTelemetry['name'], uri?: Common.IPageViewTelemetry['uri'], additionalTelemetry?: Omit<Common.IPageViewTelemetry, 'name' | 'uri'>): void;

  /**
   * Logs that a page, or similar container was displayed to the user.
   */
  public trackPageView(pageView?: Common.IPageViewTelemetry | Common.IPageViewTelemetry['name'], uri?: Common.IPageViewTelemetry['uri'], additionalTelemetry?: Omit<Common.IPageViewTelemetry, 'name' | 'uri'>): void {
    if (this.appInsights) {
      try {
        if (typeof pageView === 'string' || uri != null || additionalTelemetry != null) {
          // eslint-disable-next-line no-param-reassign
          pageView = {
            name: pageView as string,
            uri,
            ...additionalTelemetry,
          };
        }
        this.appInsights.trackPageView(pageView as Common.IPageViewTelemetry);
      } catch (ex) {
        console.warn('Angular application insights Error [trackPageView]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log a bag of performance information via the customProperties field.
   */
  public trackPageViewPerformance(pageViewPerformance: Common.IPageViewPerformanceTelemetry): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackPageViewPerformance(pageViewPerformance);
      } catch (ex) {
        console.warn('Angular application insights Error [trackPageViewPerformance]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  public trackException(exception: Common.IExceptionTelemetry): void;

  /** @deprecated */
  public trackException(exception: Error, severityLevel?: Common.SeverityLevel | number, additionalTelemetry?: Omit<Common.IExceptionTelemetry, 'error' | 'exception' | 'severityLevel'>): void;

  /**
   * Log an exception that you have caught.
   */
  public trackException(exception: Common.IExceptionTelemetry | Error, severityLevel?: Common.SeverityLevel | number, additionalTelemetry?: Omit<Common.IExceptionTelemetry, 'error' | 'exception' | 'severityLevel'>): void {
    if (this.appInsights) {
      try {
        if ('name' in exception && 'message' in exception) {
          // eslint-disable-next-line no-param-reassign
          exception = {
            exception,
            severityLevel,
            ...additionalTelemetry,
          };
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.appInsights.trackException(exception as any);
      } catch (ex) {
        console.warn('Angular application insights Error [trackException]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  public trackTrace(message: string, customProperties?: ICustomProperties, additionalTelemetry?: Omit<Common.ITraceTelemetry, 'message' | 'severityLevel'>): void;

  /** @deprecated */
  public trackTrace(trace: Common.ITraceTelemetry, customProperties?: ICustomProperties): void;

  /**
   * Log a diagnostic scenario such entering or leaving a function.
   */
  public trackTrace(trace: Common.ITraceTelemetry | string, customProperties?: ICustomProperties, additionalTelemetry?: Omit<Common.IExceptionTelemetry, 'message' | 'severityLevel'>): void {
    if (this.appInsights) {
      try {
        if (typeof trace === 'string') {
          // eslint-disable-next-line no-param-reassign
          trace = {
            message: trace,
            severityLevel: (typeof customProperties === 'number' ? customProperties : undefined),
            ...additionalTelemetry,
          };

          // eslint-disable-next-line no-param-reassign
          customProperties = undefined;
        }
        this.appInsights.trackTrace(trace, customProperties);
      } catch (ex) {
        console.warn('Angular application insights Error [trackTrace]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log a numeric value that is not associated with a specific event. Typically used
   * to send regular reports of performance indicators.
   *
   * To send a single measurement, just use the `name` and `average` fields
   * of {@param metric}.
   *
   * If you take measurements frequently, you can reduce the telemetry bandwidth by
   * aggregating multiple measurements and sending the resulting average and modifying
   * the `sampleCount` field of {@param metric}.
   */
  public trackMetric(metric: Common.IMetricTelemetry, customProperties?: ICustomProperties): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackMetric(metric, customProperties);
      } catch (ex) {
        console.warn('Angular application insights Error [trackMetric]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log a dependency call (e.g. ajax)
   */
  public trackDependencyData(dependency: Common.IDependencyTelemetry): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackDependencyData(dependency);
      } catch (ex) {
        console.warn('Angular application insights Error [trackDependencyData]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Starts the timer for tracking a page load time. Use this instead of `trackPageView` if you want to control
   * when the page view timer starts and stops, but don't want to calculate the duration yourself. This method
   * doesn't send any telemetry. Call `stopTrackPage` to log the end of the page view
   * and send the event.
   * @param name A string that identifies this item, unique within this HTML document. Defaults to the document title.
   */
  public startTrackPage(name?: string): void {
    if (this.appInsights) {
      try {
        this.appInsights.startTrackPage(name);
      } catch (ex) {
        console.warn('Angular application insights Error [startTrackPage]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Stops the timer that was started by calling `startTrackPage` and sends the pageview load time
   * telemetry with the specified properties and measurements.
   * The duration of the page view will be the time between calling `startTrackPage` and `stopTrackPage`.
   * @param   name  The string you used as the name in startTrackPage. Defaults to the document title.
   * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults
   *                to the window location.
   * @param   customProperties  map[string, string] - additional data used to filter pages and metrics in
   *                            the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics
   *                          Explorer on the portal. Defaults to empty.
   */
  public stopTrackPage(
    name?: string,
    url?: string,
    customProperties?: { [ key: string ]: string },
    measurements?: Record<string, number>,
  ): void {
    if (this.appInsights) {
      try {
        this.appInsights.stopTrackPage(name, url, customProperties, measurements);
      } catch (ex) {
        console.warn('Angular application insights Error [stopTrackPage]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Start timing an extended event. Call {@link stopTrackEvent} to log the event when it ends.
   * @param   name    A string that identifies this item, unique within this HTML document.
   */
  public startTrackEvent(name?: string): void {
    if (this.appInsights) {
      try {
        this.appInsights.startTrackEvent(name);
      } catch (ex) {
        console.warn('Angular application insights Error [startTrackEvent]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log an extended event that you started timing with `startTrackEvent`.
   * @param   name    The string you used to identify this event in `startTrackEvent`.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the
   *                      portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics
   *                          Explorer on the portal. Defaults to empty.
   */
  public stopTrackEvent(
    name: string,
    properties?: Record<string, string>,
    measurements?: Record<string, number>,
  ): void {
    if (this.appInsights) {
      try {
        this.appInsights.stopTrackEvent(name, properties, measurements);
      } catch (ex) {
        console.warn('Angular application insights Error [stopTrackEvent]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Set the authenticated user id and the account id. Used for identifying a specific signed-in user.
   * Parameters must not contain whitespace or ,;=|
   *
   * The method will only set the `authenticatedUserId` and `accountId` in the current page view. To set them
   * for the whole session, you should set `storeInCookie = true`
   */
  public setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
    if (this.appInsights) {
      try {
        this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
      } catch (ex) {
        console.warn('Angular application insights Error [setAuthenticatedUserContext]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Clears the authenticated user id and account id. The associated cookie is cleared, if present.
   */
  public clearAuthenticatedUserContext(): void {
    if (this.appInsights) {
      try {
        this.appInsights.clearAuthenticatedUserContext();
      } catch (ex) {
        console.warn('Angular application insights Error [clearAuthenticatedUserContext]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Manually trigger an immediate send of all telemetry still in the buffer.
   */
  public flush(async?: boolean): void {
    if (this.appInsights) {
      try {
        this.appInsights.flush(async);
      } catch (ex) {
        console.warn('Angular application insights Error [flush]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * TODO What does it do? Check MS docs :)
   */
  public addTelemetryInitializer(telemetryInitializer: (item: ITelemetryItem) => boolean | void): void {
    if (this.appInsights) {
      try {
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
      } catch (ex) {
        console.warn('Angular application insights Error [addTelemetryInitializer]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Initializes the service.
   * Only for internal use when providing the service through the AppInsights module.
   * @internal
   */
  public init(): void {
    if (this.config == null) {
      console.warn('You need forRoot on ApplicationInsightsModule, with instrumentationKey set at least');

      return;
    }

    if (this.config.instrumentationKey) {
      try {
        const appInsights = new ApplicationInsights({
          config: this.config,
        });
        appInsights.loadAppInsights();
        this.appInsights = appInsights;

        const router = this.getRouter();

        if (!this.config.disableAngularRouteTracking && router) {
          router.events
            .pipe(
              filter((event) => event instanceof NavigationStart),
              takeUntilDestroyed(this.componentRef),
            )
            .subscribe((event: NavigationStart) => {
              this.startTrackPage(event.url);
            });

          router.events
            .pipe(
              filter((event) => (
                event instanceof NavigationEnd
                || event instanceof NavigationCancel
                || event instanceof NavigationError
              )),
              takeUntilDestroyed(this.componentRef),
            )
            .subscribe((event: NavigationEnd) => {
              this.stopTrackPage(event.url);
            });
        }
      } catch (ex) {
        console.warn('Angular application insights Error [loadAppInsights]: ', ex);
      }
    } else {
      console.warn('An instrumentationKey value is required to initialize AppInsightsService');
    }
  }

  private getRouter(): Router | undefined {
    try {
      return this._injector.get(Router);
    } catch (ex) {
      return undefined;
    }
  }
}
